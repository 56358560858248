@import '~antd/dist/antd.less';

@import 'variables.less';
@import 'colors.less';
@import 'mixin';

@import (css)
    url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.bold {
    font-weight: 500;
}

.light {
    font-weight: 300;
}

.ant-btn-primary {
    color: #333;
    &:hover {
        color: #666;
    }
}

.ant-card-small > .ant-card-body {
    .md({
    padding: 8px;
  });

    .sm({
    padding: 6px;
  });
}

/*

 */
.flex-right-align {
    text-align: right;
}


/*
  notification
 */

.ant-notification {
    .ant-notification-notice-description {
        ul {
            margin-left: 0;
            padding-left: 1.25em;

            li {
                padding-left: 0;
                margin-left: 0;
            }
        }
    }
}

/*
  general label, e.g. optionalLabel row etc
 */
.label {
    font-size: 0.9em;
    color: #999;

    vertical-align: middle;

    &.ant-col {
        align-self: baseline;
    }
}

.ant-form-vertical {
    .ant-form-item-label {

        label {
            &:extend(.label);
        }
    }
}

/**
table style -> match it to label
 */

.ant-table-thead {
    th.ant-table-cell {
        background-color: #fff;
        font-weight: unset;
        font-size: 0.9em;
        color: #999;
    }
}
