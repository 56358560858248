.tag-label-wrapper {

  margin-right: 8px;
  font-size: 12px;
  line-height: 20px;

  height: auto;
  box-sizing: border-box;

  display: inline-block;
  border-radius: 2px;
  white-space: nowrap;

  ;


  .tag-label-tag {
    border-radius: 2px;

    padding: 1px 7px;
    display: inline-block;
  }

  .tag-label-label {
    padding: 0 7px;
    background: #777;
    color: #fff;
    padding: 1px 7px;
    display: inline-block;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

}

@primary-color: #bada55;