.avatar {
  cursor: unset;

  &.avatar--tiny {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
}

@primary-color: #bada55;