.search-result-list {


  .search-result-list-item {
    .small {
      color: #999;
      font-size: 0.75rem;
    }
  }
}

@primary-color: #bada55;