.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.responsiveMin(@minWidth; @rules) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}

.xs(@rules) {
  .responsive(768px, {
    @rules(); })
}

.sm(@rules) {
  .responsive(768px, {
    @rules(); })
}

.md(@rules) {
  .responsive(992px, {
    @rules(); })
}

.lg(@rules) {
  .responsive(1200px, {
    @rules(); })
}

.xl(@rules) {
  .responsive(1600px, {
    @rules(); })
}

.xxl(@rules) {
  @media only screen and (min-width: 1900px) {
    @rules();
  }
}

//--

.min-xs(@rules) {
  .responsiveMin(768px, {
    @rules(); })
}

.min-sm(@rules) {
  .responsiveMin(768px, {
    @rules(); })
}

.min-md(@rules) {
  .responsiveMin(992px, {
    @rules(); })
}

.min-lg(@rules) {
  .responsiveMin(1200px, {
    @rules(); })
}

.min-xl(@rules) {
  .responsiveMin(1600px, {
    @rules(); })
}

// shadows

.z-depth-transition() {
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
}

.z-depth-focus() {
  box-shadow: 0 0 8px rgba(0, 0, 0, .18), 0 8px 16px rgba(0, 0, 0, .36);
}

.z-depth-2dp() {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14),
  0 1px 5px 0 rgba(0, 0, 0, .12),
  0 3px 1px -2px rgba(0, 0, 0, .2);
}

.z-depth-3dp() {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14),
  0 1px 8px 0 rgba(0, 0, 0, .12),
  0 3px 3px -2px rgba(0, 0, 0, .4);
}

.z-depth-4dp() {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14),
  0 1px 10px 0 rgba(0, 0, 0, .12),
  0 2px 4px -1px rgba(0, 0, 0, .4);
}

.z-depth-6dp() {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14),
  0 1px 18px 0 rgba(0, 0, 0, .12),
  0 3px 5px -1px rgba(0, 0, 0, .4);
}

.z-depth-8dp() {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14),
  0 3px 14px 2px rgba(0, 0, 0, .12),
  0 5px 5px -3px rgba(0, 0, 0, .4);
}

.z-depth-16dp() {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14),
  0  6px 30px 5px rgba(0, 0, 0, .12),
  0  8px 10px -5px rgba(0, 0, 0, .4);
}

.z-depth-24dp() {
  box-shadow: 0  9px 46px  8px rgba(0, 0, 0, .14),
  0 24px 38px  3px rgba(0, 0, 0, .12),
  0 11px 15px -7px rgba(0, 0, 0, .4);
}

.z-depth(@dp: 2) {
  & when (@dp = 2) { .z-depth-2dp(); }
  & when (@dp = 3) { .z-depth-3dp(); }
  & when (@dp = 4) { .z-depth-4dp(); }
  & when (@dp = 6) { .z-depth-6dp(); }
  & when (@dp = 8) { .z-depth-8dp(); }
  & when (@dp = 16) { .z-depth-16dp(); }
  & when (@dp = 24) { .z-depth-24dp(); }
}


// Class generator
// ------------------------------------

.z-depth-classes(@transition: false, @focus: false) {
  &-transition when (@transition = true) {
    .z-depth-transition();
  }

  &-focus when (@focus = true) {
    .z-depth-focus();
  }

  // The available values for the shadow depth
  @list: 2, 3, 4, 6, 8, 16, 24;

  .generate-shadow-class(@i: 1, @count) when (@i =< @count) {
    @depth: extract(@list, @i);

    &-@{depth}dp {
      .z-depth(@depth);
    }

    .generate-shadow-class(@i + 1, @count);
  }

  .generate-shadow-class(1, 7);
}
