.extended-search {
  .extended-search-panel {
    .ant-collapse-header {
      padding: 0;
      margin: 0;
      font-size: 0.8rem;
      color: #555;

      .ant-collapse-arrow {
        color: #999;
        font-size: 0.9em;
      }
    }


  }
}

@primary-color: #bada55;