@import 'style/theme';


body {
  background-color: #f1f1f1;
}

.App {

}


.screen {
  padding: 8px;
}

@primary-color: #bada55;