.icon-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;



  .icon {
    height: 1.1em;
    text-align: center;
  }

}

@primary-color: #bada55;