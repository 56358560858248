.text-file-preview {
  border: 1px solid gray;
  margin-top: 20px;
  border-radius: 4px;
  display: flex;
  padding: 8px;

  code {
    font-size: 12px;
  }
}

@primary-color: #bada55;