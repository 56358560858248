.authentication-screen {

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  .centered {

    min-width: 600px;
    margin: 0 auto;
  }

}

@primary-color: #bada55;