@import '../../style/theme';


.main {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .layout-wrapper {
    min-height: 100vh;
  }

  .header-wrapper {
    height: 46px;
    line-height: 46px;

    .header {
      margin: 0 auto;
      max-width: @content-width;
      width: 100%;
      display: flex;
      flex-direction: row;
      color: #f1f1f1;

      font-weight: 500;

      .ant-menu {

      }

      .appname {
        color: #f1f1f1;
      }


      .user-info {

      }

      .logo {
        background-color: @layout-header-background;
        height: 46px;
        width: 60px;


        display: flex;
        align-items: center;
        justify-content: flex-start;
        //padding: 4px;
        //margin: 10px;
        //border-radius: 8px;

        img {

          margin: 0;
          padding: 0;
          height: 80%;
          max-height: 80%;
        }
      }
    }


  }

  .content-wrapper {
    margin: 16px auto;

    max-width: @content-width;
    width: 100%;


    .content {
      margin: 16px;
      margin-top: 0px;
    }
  }
}

@primary-color: #bada55;